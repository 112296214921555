import React, { useContext, useEffect, useState } from "react";
import { View } from "../components/View";
import { useLocation, useNavigate } from "react-router-dom";
import BidButton from "../components/BidButton";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { getStripeCustomerSecret } from "../util/api";
import { stripeOptions, stripePromise } from "../util/stripe";
import AuctiosLogo from "../components/AuctiosLogo";
import { url } from "../util/aws";
import { getSingleCacheData } from "../util/context";
import logo from "../assets/new_logo_black.svg";
import { FormControl, Input } from "@mui/material";
import { ThemeContext } from "../util/themeContext";

function PaymentInner({ confirmState, userID, user }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { themeStyles, theme } = useContext(ThemeContext);

  const style = {
    paymentContainer: {
      marginLeft: "10%",
      marginRight: "10%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
    header: {
      color: themeStyles.color,
      textAlign: "center",
    },
    text: {
      fontStyle: "italic",
      color: themeStyles.color,
      textAlign: "center",
    },
    errorText: {
      color: "#EF8DA2",
      textAlign: "center",
    },
    button: {
      marginTop: "5%",
      marginBottom: "5%",
    },
    errorButton: {
      borderColor: "#EF8DA2",
      borderWidth: "2px",
      marginBottom: "5%",
    },
    art: {
      width: "25%",
      alignSelf: "center",
      marginTop: "5%",
      marginBottom: "2.5%",
      filter: theme === "dark" ? "invert(1)" : "",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    textContainer: {
      color: themeStyles.color,
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      marginRight: "10%",
      marginLeft: "10%",
      fontWeight: "bold",
      fontSize: "1em",
    },
    label: {
      width: "70%",
    },
    labelText: {
      color: themeStyles.color,
      marginLeft: "2%",
      marginBottom: "3%",
    },
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!confirmState || !userID) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(async ({ setupIntent }) => {
      switch (setupIntent.status) {
        case "succeeded":
          setMessage("Success! Your payment method has been saved.");
          navigate("/bid", {
            state: {
              buyMode: location.state.buyMode,
            },
          });
          break;

        case "processing":
          setMessage(
            "Processing payment details. We'll update you when processing is complete."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage(
            "Failed to process payment details. Please try another payment method."
          );
          break;
        default:
          break;
      }
    });
  }, [confirmState, location.state.buyMode, navigate, stripe, userID]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${url}/payment`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
    }
  };

  return !user ? null : (
    <View>
      <div
        style={{
          backgroundColor: themeStyles.backgroundColor,
          paddingBottom: "5%",
        }}
      >
        {message !== null ? (
          <div style={style.paymentContainer}>
            <h3 style={style.header}>{message}</h3>
          </div>
        ) : (
          <div style={style.paymentContainer}>
            <img style={style.art} src={logo} alt="" />
            <p style={style.text}>
              Do not leave this page without setting up a payment method.
            </p>
            <div style={style.label}>
              <p style={style.labelText}>Full Name</p>
            </div>
            <FormControl style={style.inputContainer}>
              <Input
                style={style.textField}
                id="phone_number"
                value={user.full_name.S}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "white",
                  },
                }}
              />
            </FormControl>
            <div style={style.label}>
              <p style={style.labelText}>Phone Number</p>
            </div>
            <FormControl style={style.inputContainer}>
              <Input
                style={style.textField}
                id="phone_number"
                value={user.phone_number.S}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "white",
                  },
                }}
              />
            </FormControl>

            <div style={style.label}>
              <p style={style.labelText}>Payment powered by Stripe</p>
            </div>
            <PaymentElement />
            {errorMessage ? (
              <p style={style.errorText}>{errorMessage}</p>
            ) : null}
            <BidButton
              title={"Submit Payment VIA STRIPE"}
              onPress={handleSubmit}
              sx={!errorMessage ? style.button : style.errorButton}
            />
          </div>
        )}
      </div>
    </View>
  );
}

export default function Payment() {
  const location = useLocation();
  const [secret, setSecret] = useState("");
  const [confirmState, setConfirmState] = useState(false);
  const [userID, setUserID] = useState("");
  const [stripeID, setStripeID] = useState("");

  useEffect(() => {
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js

    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );
    if (clientSecret) {
      setConfirmState(true);
      setSecret(clientSecret);
    }
  }, []);

  useEffect(() => {
    async function retrieveCache() {
      if (userID === "") {
        const cached = await getSingleCacheData("user");
        setUserID(cached.userID.S);
        setStripeID(cached.stripe_id.S);
      }
    }
    retrieveCache();
  });

  useEffect(() => {
    async function getClientSecret(id) {
      const client_secret = await getStripeCustomerSecret(id);
      setSecret(client_secret);
    }

    if (!confirmState && location.state) {
      getClientSecret(location.state.user.stripe_id.S);
    }
  }, [confirmState, location, stripeID, userID]);

  stripeOptions.clientSecret = secret;

  return secret !== "" ? (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <PaymentInner
        confirmState={confirmState}
        userID={userID}
        user={location.state ? location.state.user : null}
      />
    </Elements>
  ) : (
    <View sx={{ backgroundColor: "white", paddingBottom: 0, paddingTop: 0 }}>
      <AuctiosLogo invert={true} />
    </View>
  );
}
