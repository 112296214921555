import { useContext } from "react";
import { ThemeContext } from "../util/themeContext";

export default function Card({ method }) {
  const { themeStyles } = useContext(ThemeContext);

  const style = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: themeStyles.color,
      borderRadius: 10,
      color: themeStyles.color,
      width: "70%",
      marginTop: "1%",
    },
    text: {
      color: themeStyles.backgroundColor,
      fontWeight: "600",
      fontSize: "1em",
      marginRight: "5%",
    },
    brandText: {
      color: themeStyles.backgroundColor,
      fontWeight: "600",
      fontSize: "1em",
      fontStyle: "italic",
      marginLeft: "5%",
    },
  };
  return method !== null && method !== undefined ? (
    <div style={style.buttonContainer}>
      <p style={style.brandText}>{method.card.brand}</p>
      <p style={style.text}> •••• •••• •••• {method.card.last4}</p>
    </div>
  ) : null;
}
