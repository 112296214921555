export function getImageLink(reference) {
  return `https://auctios-image-store.s3.amazonaws.com/thumbnails/${reference}`;
}

export function getImageLinkHighRes(reference) {
  return `https://auctios-image-store.s3.amazonaws.com/images/${reference}`;
}

export const PRODUCTION = true;

export const root = PRODUCTION
  ? `https://gcdhtchrt9.execute-api.us-east-2.amazonaws.com`
  : "http://localhost:3000";

export const url = PRODUCTION
  ? `https://app.auctios.xyz`
  : "http://localhost:3001";
