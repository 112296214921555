import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllArtists } from "../util/api";
import { View } from "../components/View";
import { List, ListItem } from "@mui/material";
import { getImageLink } from "../util/aws";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FloatButton from "../components/FloatButton";
import { ThemeContext } from "../util/themeContext";

export default function ArtistDashboard() {
  const { eventID } = useParams();
  const [DATA, setDATA] = useState([]);
  const navigate = useNavigate();
  const { themeStyles } = useContext(ThemeContext);

  const style = {
    textContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
    artistName: {
      color: themeStyles.color,
      fontSize: 18,
      flex: 1,
      fontStyle: "italic",
    },
    heading: {
      color: themeStyles.color,
      fontSize: 16,
      flex: 1,
      textAlign: "center",
      margin: 0,
      letterSpacing: 2,
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
    },
    item: {
      borderTop: "1px solid #333a",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: themeStyles.backgroundColor,
    },
    leftColumn: {
      flex: 1,
    },
    rightColumn: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
    },
    location: {
      fontSize: 16,
      color: themeStyles.color,
      textAlign: "center",
      margin: 3,
    },
    middleText: {
      fontSize: 10,
      color: themeStyles.color,
      textAlign: "center",
      margin: 3,
    },
    divider: {
      backgroundColor: themeStyles.color,
      width: 0.5,
      height: 60,
    },
    image: {
      width: 60,
      height: 60,
      marginRight: 15,
      marginLeft: 5,
      borderRadius: 30,
      objectFit: "cover",
    },
    header: {
      marginTop: 25,
      marginBottom: 35,
    },
  };

  useEffect(() => {
    async function fetch() {
      const art_response = await getAllArtists(eventID);
      if (art_response !== null) {
        let temp = [];
        art_response.data.Items.forEach((d, i) => {
          temp.push({
            id: i,
            artistID: d.artistID.S,
            bio: d.bio.S,
            eventID: d.eventID.S,
            full_name: d.full_name.S,
            image_ref: d.image_ref.S,
            medium: d.medium.S,
            social: d.social.S,
          });
        });
        setDATA(temp);
      }
    }
    fetch();
    return () => {};
  }, [eventID]);

  const artists = [];
  for (var i = 0; i < DATA.length; i++) {
    if (!artists.includes(DATA[i].artist_name)) {
      artists.push(DATA[i].artist_name);
    }
  }

  return (
    <View>
      <Header eventID={eventID} />

      <div style={style.headingContainer}>
        <p style={style.heading}>Artist</p>
        <p style={style.heading}>Medium</p>
      </div>
      <List
        sx={{
          height: "100%",
          width: "100%",
          marginTop: "0%",
          backgroundColor: themeStyles.backgroundColor,
        }}
        cols={1}
      >
        {DATA.map((artist, index) => (
          <ListItem
            key={index}
            sx={style.item}
            onClick={() => navigate(`/?artist=${artist.artistID}`)}
          >
            <div style={style.leftColumn}>
              <div style={style.textContainer}>
                <img
                  style={style.image}
                  src={getImageLink(artist.image_ref)}
                  alt=""
                />
                <p style={style.artistName}>{artist.full_name}</p>
              </div>
            </div>
            <div style={style.divider}></div>
            <div style={style.rightColumn}>
              <div>
                <p style={style.location}>{`${artist.medium}`}</p>
              </div>
            </div>
          </ListItem>
        ))}
      </List>

      <FloatButton
        onClick={() => navigate("/dashboard/" + eventID)}
        view="Dashboard"
      />

      <Footer />
    </View>
  );
}
