import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useContext } from "react";
import { ThemeContext } from "../util/themeContext";
import InfoIcon from "@mui/icons-material/Info";
import { getImageLink } from "../util/aws";
import Artwork from "./Artwork";

const formatSocial = (social) => {
  if (social === null) {
    return;
  }
  if (social.indexOf("@") > -1) {
    return social.substring(1);
  }
  return social;
};

const ArtCarousel = ({ art, artist, image }) => {
  const navigate = useNavigate();
  const { themeStyles } = useContext(ThemeContext);

  const style = {
    infoHeader: {
      fontStyle: "italic",
      color: themeStyles.color,
      fontSize: 20,
      fontWeight: "bold",
      margin: 0,
    },
    infoContainer: {
      padding: "5%",
      border: "2px solid",
      borderRadius: 20,
      borderColor: themeStyles.color,
      marginBottom: 40,
    },
    infoText: {
      color: themeStyles.color,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 3,
      marginBottom: 5,
      textAlign: "left",
      fontFamily: "Optima, Times, serif",
    },
    carousel: {
      width: "85%",
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      backgroundColor: themeStyles.backgroundColor,
      border: "none",
      padding: 0,
      width: "100%",
    },
    noMargin: {
      margin: 0,
    },
    artistInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    artistBar: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "2.5%",
    },
    artistBarLeft: {
      display: "flex",
    },
    QRContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: "10%",
    },
    img: {
      objectFit: "contain",
      width: "100%",
      height: "100%",
      marginBottom: "10%",
      borderRadius: 3,
    },
    slide: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: 60,
      height: 60,
      borderRadius: 30,
      marginRight: 15,
      objectFit: "cover",
    },
  };

  const dimensions = art.dimension;
  const x = dimensions.indexOf("x");
  const width = dimensions.substring(0, x);
  const height = dimensions.substring(x + 1);

  const _navArtist = () => {
    navigate("/artist-display?artist=" + art.artistID);
  };

  return (
    <Swiper
      spaceBetween={50}
      style={style.carousel}
      pagination={true}
      modules={[Pagination]}
      autoHeight={true}
    >
      <SwiperSlide style={style.noMargin}>
        <Artwork image={image} />
      </SwiperSlide>
      <SwiperSlide style={style.noMargin}>
        <div style={style.infoContainer}>
          <button style={style.button} onClick={_navArtist}>
            <div style={style.artistInfo}>
              <p style={style.infoHeader}>Artist</p>
              <div style={style.artistBar}>
                <div style={style.artistBarLeft}>
                  <img
                    style={style.image}
                    src={getImageLink(artist.image_ref)}
                    alt=""
                  />
                  <div>
                    <p style={style.infoText}>
                      <a
                        href={`https://instagram.com/${formatSocial(
                          artist.social
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: themeStyles.color,
                        }}
                        onClick={(event) => event.stopPropagation()}
                      >
                        @{formatSocial(artist.social)}
                      </a>
                    </p>
                    <p style={style.infoText}>{`${artist.medium}`}</p>
                  </div>
                </div>
                <div className="info">
                  <InfoIcon
                    fontSize="large"
                    sx={{ color: themeStyles.color }}
                  />
                </div>
              </div>
            </div>
          </button>
          <hr />
          {width === "0" && height === "0" ? null : (
            <div>
              <p style={style.infoHeader}>Dimensions</p>
              <p style={style.infoText}>
                {width}" x {height}"
              </p>
              <hr />
            </div>
          )}
          <p style={style.infoHeader}>Description</p>
          <p style={style.infoText}>{art.description}</p>
        </div>
      </SwiperSlide>
      <SwiperSlide style={style.QRContainer}>
        <QRCode value={`https://app.auctios.xyz/?id=${art.artID}`} />
      </SwiperSlide>
    </Swiper>
  );
};

export default ArtCarousel;
