import { List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuctiosLogo from "../components/AuctiosLogo";
import { View } from "../components/View";
import { getAllArt, getAuction, getTopBidder } from "../util/api";
import { formatPrice } from "../util/util";
import Countdown, { zeroPad } from "react-countdown";
import { getImageLink } from "../util/aws";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FloatButton from "../components/FloatButton";
import { useContext } from "react";
import { ThemeContext } from "../util/themeContext";

function formatTitle(title) {
  let str = title.toString();
  return str.length < 21 ? str.trim() : str.substring(0, 21).trim() + "...";
}

function getPrice(art) {
  return art.bid_price !== "0" ? art.bid_price : art.min_price;
}

const useStyle = () => {
  const { themeStyles } = useContext(ThemeContext);

  const itemStyle = {
    borderTop: "1px solid #333a",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: themeStyles.backgroundColor,
  };

  const style = {
    header: {
      fontWeight: "800",
      textAlign: "center",
    },
    headerContainer: {
      backgroundColor: themeStyles.color,
      position: "fixed",
      zIndex: 1,
      width: "100%",
    },
    timeHeader: {
      textAlign: "center",
      color: themeStyles.color,
      fontSize: 20,
      letterSpacing: 2.5,
      fontWeight: 400,
    },
    timeHeadingContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    item: {
      ...itemStyle,
    },
    firstItem: {
      ...itemStyle,
      borderTop: "none",
    },
    footerText: {
      color: themeStyles.color,
      fontSize: 12,
      marginTop: 1,
      marginBottom: 1,
    },
    title: {
      fontStyle: "italic",
      fontWeight: "700",
      textDecoration: "underline",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      marginRight: "5%",
    },
    bidContainer: {
      whiteSpace: "nowrap",
      textAlign: "right",
    },
    art: {
      width: "15%",
      alignSelf: "center",
    },
    itemContainer: {
      display: "flex",
      width: "100%",
    },
    itemComponentLeft: {
      display: "flex",
      flex: 1.3,
      alignItems: "center",
    },
    itemComponentRight: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    startBidText: {
      color: themeStyles.color,
      fontSize: 12,
      fontStyle: "italic",
    },
    itemPrice: {
      color: themeStyles.color,
      fontSize: 30,
      margin: 5,
    },
    itemTitle: {
      color: themeStyles.color,
      fontSize: 20,
      fontStyle: "italic",
      margin: 0,
      wordBreak: "break-word",
      paddingRight: 10,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    itemInformation: {
      display: "flex",
      flexDirection: "column",
    },
    itemArtist: {
      color: themeStyles.color,
      fontSize: 12,
      fontStyle: "italic",
      wordBreak: "break-word",
      whiteSpace: "normal",
    },
    divider: {
      backgroundColor: themeStyles.color,
      width: 1,
    },
    footerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
      marginBottom: "5%",
    },
    countdownText: {
      color: themeStyles.color,
      fontSize: 56,
      flex: 1,
      textAlign: "center",
    },
    countdownContainer: {
      display: "flex",
      alignItems: "center",
      margin: 0,
      width: "75%",
      marginBottom: 10,
      justifyContent: "center",
    },
    countdownLabel: {
      fontSize: 14,
      color: themeStyles.color,
      flex: 1,
      textAlign: "center",
    },
    countdownLabelContainer: {
      display: "flex",
      justifyContent: "center",
      width: "75%",
    },
    countdownLargeContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginBottom: 20,
    },
    thumbnail: {
      width: "100%",
      objectFit: "cover",
    },
    thumbnailContainer: {
      display: "flex",
      minWidth: 60,
      maxWidth: 60,
      marginRight: 10,
      border: "solid",
      borderColor: themeStyles.color,
      borderRadius: 3,
    },
    logo: {
      width: "10%",
      alignSelf: "center",
      marginTop: "2.5%",
      marginBottom: "2.5%",
    },
    heading: {
      marginTop: 75,
      marginBottom: 25,
    },
  };
  return style;
};

export default function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [beginLoading, setBeginLoading] = useState(false);
  const [endTime, setEndTime] = useState(Date.now());
  const { themeStyles } = useContext(ThemeContext);

  const [DATA, setDATA] = useState([]);
  const { eventID } = useParams();
  const style = useStyle();

  useEffect(() => {
    if (endTime === null) {
      getAuction(eventID).then((res) => {
        setEndTime(new Date(res.data.Item.end_time.S));
      });
    }
  }, [endTime, eventID]);

  useEffect(() => {
    let cancel = false;

    if (!beginLoading) {
      getAuction(eventID).then((res) => {
        if (cancel) return;
        let current = new Date().getTime();
        let start = new Date(res.data.Item.start_time.S);
        setBeginLoading(current >= start);
        setEndTime(new Date(res.data.Item.end_time.S));
      });
    }

    return () => {
      cancel = true;
    };
  }, [beginLoading, eventID]);

  useEffect(() => {
    let cancel = false;
    async function fetch() {
      const art_response = await getAllArt(eventID);
      if (cancel) return;
      if (art_response !== null) {
        let temp = [];
        art_response.data.Items.forEach((d, i) => {
          if (d.min_price.N !== 0) {
            temp.push({
              id: i,
              title: d.title.S,
              min_price: d.min_price.N,
              top_bidder: d.top_bidder.S,
              bid_price: d.bid_price.N,
              artID: d.artID.S,
              image_ref: d.image_ref.S,
              artistID: d.artistID.S,
              artist_name: d.artist_name.S,
            });
          }
        });
        for (var i = 0; i < temp.length; i++) {
          if (temp[i].top_bidder !== undefined && temp[i].top_bidder !== "") {
            const bidder_response = await getTopBidder(temp[i].top_bidder);
            temp[i].top_bidder = bidder_response.full_name;
            temp[i].bid_price = bidder_response.price;
          }
        }
        temp.sort((a, b) => {
          const priceA = getPrice(a);
          const priceB = getPrice(b);

          // Move zero priced items to the end
          if (priceA <= 0) return 1;
          if (priceB <= 0) return -1;

          // Otherwise sort normally
          return priceA - priceB;
        });
        setDATA(temp);
        setLoading(false);
      }
    }
    if (DATA.length === 0) {
      fetch();
    }
    return () => {
      cancel = true;
    };
  }, [DATA.length, beginLoading, eventID]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (days > 0) {
      return (
        <div style={style.countdownLargeContainer}>
          <div style={style.countdownContainer}>
            <span style={style.countdownText}>{zeroPad(days)}</span>
            <span style={style.countdownText}>{zeroPad(hours)}</span>
            <span style={style.countdownText}>{zeroPad(minutes)}</span>
            <span style={style.countdownText}>{zeroPad(seconds)}</span>
          </div>
          <div style={style.countdownLabelContainer}>
            <span style={style.countdownLabel}>days</span>
            <span style={style.countdownLabel}>hours</span>
            <span style={style.countdownLabel}>minutes</span>
            <span style={style.countdownLabel}>seconds</span>
          </div>
        </div>
      );
    } else {
      return (
        <div style={style.countdownLargeContainer}>
          <div style={style.countdownContainer}>
            <span style={style.countdownText}>{zeroPad(hours)}</span>
            <span style={style.countdownText}>{zeroPad(minutes)}</span>
            <span style={style.countdownText}>{zeroPad(seconds)}</span>
          </div>
          <div style={style.countdownLabelContainer}>
            <span style={style.countdownLabel}>hours</span>
            <span style={style.countdownLabel}>minutes</span>
            <span style={style.countdownLabel}>seconds</span>
          </div>
        </div>
      );
    }
  };

  return loading ? (
    <View sx={{ backgroundColor: "white", paddingBottom: 0, paddingTop: 0 }}>
      <AuctiosLogo />
    </View>
  ) : (
    <View sx={{ paddingTop: 0 }}>
      <Header eventID={eventID} />
      <div style={style.timeHeadingContainer}>
        <p style={style.timeHeader}>Time Remaining</p>
        <Countdown date={endTime} renderer={renderer} />
      </div>
      <List
        sx={{
          height: "100%",
          width: "100%",
          marginTop: "0%",
          backgroundColor: themeStyles.backgroundColor,
        }}
        cols={1}
      >
        {DATA.map((art, index) => (
          <ListItem
            key={index}
            sx={index === 0 ? style.firstItem : style.item}
            onClick={() => navigate(`/?id=${art.artID}`)}
          >
            <div style={style.itemContainer}>
              <div style={style.itemComponentLeft}>
                <div style={style.thumbnailContainer}>
                  <img
                    style={style.thumbnail}
                    src={getImageLink(art.image_ref)}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div style={style.itemInformation}>
                  <p style={style.itemTitle}>{formatTitle(art.title)}</p>
                  <p style={style.itemArtist}>{art.artist_name}</p>
                </div>
              </div>

              <div style={style.divider}></div>
              {art.min_price > 0 ? (
                <div style={style.itemComponentRight}>
                  <p style={style.startBidText}>
                    {`Starting bid: $${art.min_price}`}
                  </p>
                  <p style={style.itemPrice}>{`$${formatPrice(
                    getPrice(art)
                  )}`}</p>
                </div>
              ) : (
                <div style={style.itemComponentRight}>
                  <p style={style.startBidText}>Not For Sale</p>
                </div>
              )}
            </div>
          </ListItem>
        ))}
      </List>

      <FloatButton
        onClick={() => navigate("/artist-dashboard/" + eventID)}
        view="Artists"
      />

      <Footer />
    </View>
  );
}
