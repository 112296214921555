import { useNavigate } from "react-router-dom";
import AuctiosLogo from "../components/AuctiosLogo";
import { View } from "../components/View";
import { useLocation } from "react-router-dom";

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  return (
    <View sx={style.view}>
      <div
        style={style.container}
        onClick={() => {
          navigate("/dashboard/" + state.art.eventID, {
            state: state,
          });
        }}
      >
        <h1 style={style.heading}>
          {location.state.buyMode ? "Purchase Confirmed" : "Bid Confirmed"}
        </h1>
        <AuctiosLogo invert={true} />
        <p style={style.text}>
          Click anywhere to navigate back to the auction.
        </p>
      </div>
    </View>
  );
};

const style = {
  view: {
    backgroundColor: "black",
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: "3em",
    color: "white",
  },
  text: {
    color: "white",
    marginTop: "15em",
    fontSize: "1.5em",
    marginRight: 25,
    marginLeft: 25,
  },
};

export default Confirmation;
