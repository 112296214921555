import blackLogo from "../assets/new_logo_black.svg";
import { useContext } from "react";
import { ThemeContext } from "../util/themeContext";

export default function Footer() {
  const { theme, themeStyles } = useContext(ThemeContext);

  const style = {
    footerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
      backgroundColor: themeStyles.backgroundColor,
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    footerText: {
      color: themeStyles.color,
      fontSize: 12,
      marginTop: 1,
      marginBottom: 1,
    },
    art: {
      width: "7.5%",
      alignSelf: "center",
      marginTop: "2.5%",
      marginBottom: "2.5%",
      filter: theme === "dark" ? "invert(1)" : "",
    },
    link: {
      color: themeStyles.color,
    },
  };

  return (
    <div style={style.footerContainer}>
      <img style={style.art} src={blackLogo} alt="" />
      <div>
        <p style={style.footerText}>Powered by Auctios v4.2.4</p>
        <p style={style.footerText}>
          Contact{" "}
          <a
            href="mailto:info@auctios.xyz"
            style={style.link}
            target="_blank"
            rel="noreferrer"
          >
            info@auctios.xyz
          </a>{" "}
          for more information
        </p>
      </div>
    </div>
  );
}
