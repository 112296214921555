import React, { useState } from "react";

const Artwork = ({ image, sx }) => {
  const [loading, handleLoading] = useState(true);
  const [previewLoading, handlePreviewLoad] = useState(true);

  const style = {
    preload: {
      filter: "blur(3px)",
      objectFit: "contain",
      width: "100%",
      height: "100%",
      marginBottom: "10%",
      borderRadius: 3,
      transition: "filter 1s ease-in-out",
    },
    doneload: {
      display: "none",
      opacity: 0,
    },
    load: {
      objectFit: "contain",
      width: "100%",
      height: "100%",
      marginBottom: "10%",
      borderRadius: 3,
      filter: "blur(0)",
      transition: "filter 1s ease-in-out",
      ...sx,
    },
  };

  return (
    <div>
      <div style={previewLoading ? style.preload : style.doneload}></div>
      <img
        src={image.thumb}
        style={loading && !previewLoading ? style.preload : style.doneload}
        alt=""
        loading="eager"
        onLoad={() => handlePreviewLoad(false)}
      />
      <img
        src={image.img}
        style={loading ? style.doneload : style.load}
        alt=""
        loading="eager"
        onLoad={() => handleLoading(false)}
      />
    </div>
  );
};

export default Artwork;
