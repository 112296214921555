import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../util/themeContext";

const AddCardButton = ({ user }) => {
  const navigate = useNavigate();

  const { themeStyles } = useContext(ThemeContext);
  const style = {
    buttonContainer: {
      marginTop: "9%",
      backgroundColor: themeStyles.backgroundColor,
      display: "flex",
      alignItems: "stretch",
      padding: 0,
      width: "70%",
      border: "none",
    },
    textContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      padding: "2%",
      borderRadius: 10,
      backgroundColor: themeStyles.color,
    },
    text: {
      fontSize: "1.25em",
      color: themeStyles.backgroundColor,
      fontWeight: "bold",
      lineHeight: 0.5,
      textTransform: "uppercase",
    },
  };

  return (
    <button
      style={{ ...style.buttonContainer }}
      onClick={() => {
        navigate("/payment", {
          state: {
            user: user,
          },
        });
      }}
    >
      <div style={style.textContainer}>
        <p style={style.text}>Add Payment Method</p>
      </div>
    </button>
  );
};

export default AddCardButton;
