import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { View } from "../components/View";
import Thumbnail from "../components/Thumbnail";
import { getSingleCacheData } from "../util/context";
import BidForm from "../components/BidForm";
import AuctiosLogo from "../components/AuctiosLogo";
import Header from "../components/Header";
import BuyForm from "../components/BuyForm";

export default function Bid() {
  const location = useLocation();
  const navigate = useNavigate();
  const [art, setArt] = useState(null);
  const [artistName, setArtistName] = useState(null);
  const [image, setImage] = useState(null);
  const [topBidder, setTopBidder] = useState(null);
  const [bidder, setBidder] = useState(null);
  const [loadUser, handleLoadUser] = useState(true);
  const [loadArt, handleLoadArt] = useState(true);
  const [loadBid, handleLoadBid] = useState(false);
  const buyMode = location.state.buyMode;

  async function retrieveArtCache() {
    handleLoadArt(true);
    const cached = await getSingleCacheData("art");
    if (cached !== null && cached !== false) {
      setArt(cached.art_state);
      setImage(cached.image_state.thumb);
      setTopBidder(cached.bidder_state);
      setArtistName(cached.artist_state.full_name);
    }
    handleLoadArt(false);
  }

  useEffect(() => {
    async function retrieveUserCache() {
      handleLoadUser(true);
      const userCache = await getSingleCacheData("user");
      if (userCache === null || userCache === false) {
        if (location.state && location.state.user) {
          setBidder({
            full_name: location.state.user.full_name.S,
            userID: location.state.user.userID.S,
            phone_number: location.state.user.phone_number.S,
          });
        } else {
          return;
        }
      } else {
        setBidder({
          full_name: userCache.full_name.S,
          userID: userCache.userID.S,
          phone_number: userCache.phone_number.S,
        });
      }
      handleLoadUser(false);
    }

    if (loadArt) {
      retrieveArtCache();
    }
    if (loadUser) {
      retrieveUserCache();
    }
  }, [loadArt, loadUser, location.state]);

  return loadUser || loadArt || loadBid ? (
    <View sx={{ backgroundColor: "white", paddingBottom: 0, paddingTop: 0 }}>
      <AuctiosLogo />
    </View>
  ) : (
    <View sx={{ paddingTop: 0, paddingBottom: "7.5%" }}>
      <Header
        eventID={art.eventID}
        onClick={() => {
          navigate(`/?id=${art.artID}`);
        }}
        _back={true}
      />

      <Thumbnail
        artist={artistName}
        title={art.title}
        bid_price={art.min_price}
        img={image}
      />
      {!buyMode ? (
        <BidForm
          handleLoading={handleLoadBid}
          art={art}
          image={image}
          topBidder={topBidder}
          bidder={bidder}
        />
      ) : (
        <BuyForm
          handleLoading={handleLoadBid}
          art={art}
          image={image}
          topBidder={topBidder}
          bidder={bidder}
        />
      )}
    </View>
  );
}
