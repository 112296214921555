import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { View } from "../components/View";
import Thumbnail from "../components/Thumbnail";
import { addDataIntoCache, getSingleCacheData } from "../util/context";
import Login from "../components/Login";
import Header from "../components/Header";

export default function PreBid() {
  const location = useLocation();
  const navigate = useNavigate();
  const image = location.state.img;
  const art = location.state.art;
  const artist = location.state.artist;
  const buyMode = location.state.buyMode;

  useEffect(() => {
    async function retrieveCache() {
      const cached = await getSingleCacheData("art");
      if (cached === null) {
        addDataIntoCache("art", location.state);
      }
    }
    retrieveCache();
  }, [location.state]);

  useEffect(() => {
    async function retrieveCache() {
      const user = await getSingleCacheData("user");
      if (user) {
        navigate("/bid", {
          state: { buyMode: buyMode },
        });
      }
    }
    retrieveCache();
  }, [navigate, buyMode]);

  return (
    <View sx={{ paddingTop: 0, paddingBottom: "7.5%" }}>
      <Header
        eventID={art.eventID}
        onClick={() => navigate(`/?id=${art.artID}`)}
        _back={true}
      />
      <Thumbnail
        artist={artist.full_name}
        title={art.title}
        bid_price={art.min_price}
        img={image}
      />
      <Login buyMode={buyMode} />
    </View>
  );
}
