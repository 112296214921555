import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { View } from "../components/View";
import { getSingleCacheData } from "../util/context";
import AuctiosLogo from "../components/AuctiosLogo";
import LogoutButton from "../components/LogoutButton";
import Header from "../components/Header";
import { ThemeContext } from "../util/themeContext";
import { listPaymentMethods } from "../util/api";
import Card from "../components/Card";
import Footer from "../components/Footer";
import DarkModeSwitch from "../components/DarkModeSwitch";

export default function Account() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [eventID, setEventID] = useState("");
  const [stripeID, setStripeID] = useState("");
  const [method, setMethod] = useState(null);

  const [loading, setLoading] = useState(true);
  const { themeStyles } = useContext(ThemeContext);

  useEffect(() => {
    async function retrieveCache() {
      // get user info from cache
      const user = await getSingleCacheData("user");

      setStripeID(user.stripe_id.S); // used for displaying card info
      setPhone(user.phone_number.S); // phone
      setName(user.full_name.S); // name

      const cached = await getSingleCacheData("art");
      setEventID(cached.art_state.eventID);

      setLoading(false);
    }
    if (eventID === "" || name === "" || phone === "") {
      retrieveCache();
    }
  }, [name, phone, eventID]);

  useEffect(() => {
    let cancel = false;
    async function getPaymentMethods() {
      if (stripeID === "") {
        return;
      }
      const res = await listPaymentMethods(stripeID);
      if (cancel) return;
      if (res === null || res === undefined) {
        return;
      }
      setMethod(res.data.paymentMethods.data[0]);
    }
    if (stripeID !== "") {
      getPaymentMethods();
    }
    return () => {
      cancel = true;
    };
  }, [stripeID]);

  const style = {
    art: {
      width: "25%",
      alignSelf: "center",
      marginTop: "5%",
      marginBottom: "2.5%",
    },
    text: {
      color: themeStyles.color,
      fontSize: "1em",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "0%",
    },
    container: {
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "10%",
      marginBottom: "90%",
    },
    inputContainer: {
      width: "90%",
      marginBottom: "5%",
    },
    textContainer: {
      borderRadius: 10,
      width: "70%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "10%",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    labels: {
      color: themeStyles.color,
      flex: "1",
      alignItems: "flex-start",
      fontSize: ".8em",
      marginBottom: "1%",
      marginTop: "0",
    },
    header: {
      color: themeStyles.color,
      fontSize: "1.5em",
      fontWeight: "bold",
      textAlign: "center",
      textDecoration: "underline",
      marginBottom: "5%",
      marginTop: "0",
    },
  };

  return loading ? (
    <View sx={{ backgroundColor: "black", paddingBottom: 0, paddingTop: 0 }}>
      <AuctiosLogo invert={true} />
    </View>
  ) : (
    <View sx={{ paddingTop: 0, paddingBottom: "7.5%" }}>
      <Header
        onClick={() => navigate(-1)}
        eventID={eventID}
        _disable_profile={true}
        _back={true}
      />

      <div style={style.container}>
        <div style={style.textContainer}>
          <p style={style.header}>Account Information</p>
          <p style={style.labels}>Name</p>
          <p style={style.text}>{name}</p>
          <p style={style.labels}>Phone Number</p>
          <p style={style.text}>{phone}</p>
        </div>
        <p style={style.labels}>Payment Method</p>
        <Card method={method} />
        <DarkModeSwitch />
      </div>
      <Footer />
      <LogoutButton id={eventID} />
    </View>
  );
}
