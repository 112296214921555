import { FormControl } from "@mui/material";
import { useContext } from "react";
import Input from "react-phone-number-input/input";
import { ThemeContext } from "../util/themeContext";

export default function PhoneInput({ phone, setPhone, buyMode }) {
  const { themeStyles } = useContext(ThemeContext);

  const _setPhone = () => {
    let _phone = document.getElementById("phone").value;
    setPhone(_phone);
  };

  const style = {
    text: {
      color: themeStyles.color,
      fontSize: "1em",
      marginLeft: "2.5%",
      marginBottom: "4%",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "7.5%",
      paddingBottom: "7.5%",
      fontSize: "1em",
      fontWeight: "bold",
      letterSpacing: "2px",
      border: 0,
    },
    inputContainer: {
      width: "70%",
      marginBottom: "5%",
    },
  };

  return (
    <FormControl error={phone === "error"} style={style.inputContainer}>
      <p style={style.text}>{`Enter phone number to place ${
        !buyMode ? "bid" : "purchase"
      }`}</p>
      <Input
        style={style.textField}
        id="phone"
        placeholder={"Phone Number"}
        onChange={_setPhone}
        defaultCountry="US"
      />
    </FormControl>
  );
}
