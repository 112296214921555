import HttpsIcon from "@mui/icons-material/Https";
import React, { useContext } from "react";
import { ThemeContext } from "../util/themeContext";

const SecureButton = ({ onPress, buyMode }) => {
  const { themeStyles } = useContext(ThemeContext);

  const style = {
    buttonContainer: {
      marginTop: "9%",
      backgroundColor: themeStyles.backgroundColor,
      display: "flex",
      alignItems: "stretch",
      padding: 0,
      width: "70%",
      border: "none",
    },
    textContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      padding: "2%",
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      backgroundColor: "white",
      border: "solid",
      borderWidth: 1,
      borderColor: "black",
    },
    iconContainer: {
      backgroundColor: "gray",
      display: "flex",
      flex: 1 / 3,
      justifyContent: "center",
      alignItems: "center",
      padding: "2%",
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      border: "solid",
      borderWidth: 1,
      borderColor: "black",
    },
    text: {
      fontSize: "1.25em",
      color: "black",
      fontWeight: "bold",
      lineHeight: 0.5,
      textTransform: "uppercase",
    },
    icon: {
      color: "black",
    },
  };

  return (
    <button style={{ ...style.buttonContainer }} onClick={onPress}>
      <div style={style.textContainer}>
        <p style={style.text}>{!buyMode ? "place bid" : "purchase"}</p>
      </div>
      <div style={style.iconContainer}>
        <HttpsIcon style={style.icon} />
      </div>
    </button>
  );
};

export default SecureButton;
