export default function FloatButton({ onClick, view }) {
  return (
    <div style={style.container}>
      <div style={style.headerContainer} onClick={onClick}>
        <p style={style.header}>View {view}</p>
      </div>
    </div>
  );
}

const style = {
  header: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 16,
    color: "white",
  },
  headerContainer: {
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 1px 20px #888888",
    borderRadius: 50,
    width: 200,
    alignSelf: "flex-end",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
    zIndex: 999,
    position: "fixed",
    bottom: "2.5%",
    right: "2.5%",
  },
};
