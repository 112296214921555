import React, { useState, useEffect, useContext } from "react";
import {
  getArt,
  getAuction,
  getTopBidder,
  getArtist,
  getArtistByID,
} from "../util/api";
import { Navigate, useLocation } from "react-router-dom";
import AuctiosLogo from "../components/AuctiosLogo";
import { addDataIntoCache, Context, getSingleCacheData } from "../util/context";
import { View } from "../components/View";
import { getImageLink, getImageLinkHighRes } from "../util/aws";
import { ThemeContext } from "../util/themeContext";

const Loading = () => {
  const [auction, setAuction] = useState(null);
  const [art, setArt] = useState(null);
  const [topBidder, setTopBidder] = useState(null);
  const [artArtist, setArtArtist] = useState(null);
  const [image, setImage] = useState(null);
  const [artist, setArtist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useContext(Context);
  const location = useLocation();
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    let cancel = false;

    async function loadArt() {
      const res = await getArt();
      if (cancel) return;
      setArt(res.data.Item);
    }
    loadArt();

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    let cancel = false;

    async function loadAuction(eventID) {
      const res = await getAuction(eventID);
      if (cancel) return;
      setAuction(res.data.Item);
      const darkmode = await getSingleCacheData("darkmode");
      if (darkmode === null) {
        addDataIntoCache("darkmode", res.data.Item.dark_mode.BOOL);
        if (res.data.Item.dark_mode.BOOL) {
          setTheme("dark");
        } else {
          setTheme("light");
        }
      } else if (darkmode) {
        setTheme("dark");
      } else {
        setTheme("light");
      }
    }

    if (art !== null && art !== undefined) {
      loadAuction(art.eventID);
    }

    return () => {
      cancel = true;
    };
  }, [art, setTheme]);

  useEffect(() => {
    let cancel = false;
    if (art !== null && art !== undefined) {
      getTopBidder(art.top_bidder).then((res) => {
        if (cancel) return;
        setTopBidder(res);
      });
    }

    return () => {
      cancel = true;
    };
  }, [art]);

  useEffect(() => {
    let cancel = false;
    if (art !== null && art !== undefined) {
      getArtistByID(art.artistID).then((res) => {
        if (cancel) return;
        setArtArtist(res);
      });
    }

    return () => {
      cancel = true;
    };
  }, [art]);

  useEffect(() => {
    let cancel = false;
    if (art !== null && art !== undefined) {
      if (art.image_ref === null) {
        art.image_ref.S = "logo_white.png";
      }
      const res = getImageLink(art.image_ref);
      const resHigh = getImageLinkHighRes(art.image_ref);
      if (cancel) return;
      setImage({
        img: resHigh,
        thumb: res,
      });
    }
    return () => {
      cancel = true;
    };
  }, [art]);

  useEffect(() => {
    let cancel = false;

    async function loadArtist() {
      const res = await getArtist();
      if (cancel) return;
      if (res === undefined || res === null) {
        return;
      }
      setArtist(res.data.Item);
    }
    loadArtist();

    return () => {
      cancel = true;
    };
  }, [art]);

  useEffect(() => {
    if (
      art !== null &&
      topBidder !== null &&
      image !== null &&
      auction !== null &&
      artArtist !== null
    ) {
      dispatch({
        type: "LOAD",
        payload: {
          art: art,
          image: image,
          bidder: topBidder,
          auction: auction,
          artist: artArtist,
        },
      });
      setLoading(false);
    } else if (artist !== null) {
      dispatch({
        type: "ARTIST_LOAD",
        payload: {
          artist: artist,
        },
      });
    }
  }, [art, auction, dispatch, image, topBidder, artist, artArtist]);

  if (artist !== null && artist !== undefined) {
    return (
      <Navigate
        to={"/artist-display" + location.search}
        state={{ id: artist.artistID.S }}
      />
    );
  }
  return loading ? (
    <View sx={{ backgroundColor: "white", paddingBottom: 0, paddingTop: 0 }}>
      <AuctiosLogo />
    </View>
  ) : (
    <Navigate to={"/display" + location.search} state={{ id: art.artID.S }} />
  );
};

export default Loading;
