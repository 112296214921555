import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  capturePayment,
  createBid,
  notifyTopBidderPurchase,
  notifyUserPurchase,
} from "../util/api";
import { ThemeContext } from "../util/themeContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, Input } from "@mui/material";
import { getSingleCacheData } from "../util/context";
import Card from "./Card";
import SecureButton from "./SecureButton";
import { listPaymentMethods } from "../util/api";
import AddCardButton from "./AddCardButton";

const BuyForm = ({ handleLoading, art, image, topBidder, bidder }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stripeID, setStripeID] = useState("");
  const [state, setState] = useState(null);
  const [method, setMethod] = useState(null);
  const [user, setUser] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirm) => {
    setOpen(false);
    if (!confirm) {
      handleLoading(true);
      submit();
    }
  };

  useEffect(() => {
    let cancel = false;
    async function retrieveCache() {
      const user = await getSingleCacheData("user");
      if (cancel) return;
      setState({
        full_name: user.full_name.S,
        phone_number: user.phone_number.S,
      });
      setStripeID(user.stripe_id.S);
      setUser(user);
      setLoading(false);
    }
    if (state === null) {
      retrieveCache();
    }
    return () => {
      cancel = true;
    };
  }, [state]);

  useEffect(() => {
    let cancel = false;
    async function getPaymentMethods() {
      if (stripeID === "") {
        return;
      }
      const res = await listPaymentMethods(stripeID);
      if (cancel) return;
      if (res === null || res === undefined) {
        return;
      }
      setMethod(res.data.paymentMethods.data[0]);
    }
    if (stripeID !== "") {
      getPaymentMethods();
    }
    return () => {
      cancel = true;
    };
  }, [stripeID]);

  const navigate = useNavigate();

  const Confirmation = () => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By confirming this purchase, you will be charged ${art.buy_price}{" "}
              on the provided card and will be the new owner of this piece.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => handleClose(true)}>
              Disagree
            </Button>
            <Button onClick={() => handleClose(false)}>Agree</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const submit = () => {
    var newBid = {
      full_name: bidder.full_name,
      artID: art.artID,
      userID: bidder.userID,
      price: parseInt(art.buy_price),
      buy_now: true,
    };
    const paymentObj = {
      amount: parseInt(art.buy_price),
      customerID: stripeID,
      name: bidder.full_name,
      description: art.title + " by " + art.artist_name,
      phone_number: state.phone_number,
      event_id: art.eventID,
    };
    createBid(newBid)
      .catch((err) => {
        console.log("Error creating purchase bid: ", err);
        return Promise.reject(err);
      })
      .then(() => {
        capturePayment(paymentObj)
          .catch((err) => {
            console.log("Error capturing payment: ", err);
            return Promise.reject(err);
          })
          .then(() => {
            notifyUserPurchase(bidder, newBid);
            notifyTopBidderPurchase(topBidder, newBid);
            navigate("/confirmation", {
              state: {
                bid: newBid,
                img: image,
                topBidder,
                art,
                buyMode: true,
              },
            });
          });
      });
  };

  const { themeStyles } = useContext(ThemeContext);
  const style = {
    container: {
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    bidContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    inputContainer: {
      width: "70%",
      marginBottom: 20,
    },
    infoContainer: {
      width: "70%",
      marginBottom: "2.5%",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    infoField: {
      backgroundColor: "#CECECE",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "3%",
      paddingBottom: "3%",
    },
    textContainer: {
      color: themeStyles.color,
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      marginRight: "10%",
      marginLeft: "10%",
      fontWeight: "bold",
      fontSize: "1em",
    },
    topLabel: {
      width: "70%",
      marginTop: "-3%",
    },
    label: {
      width: "70%",
    },
    labelText: {
      color: themeStyles.color,
      marginLeft: "2%",
      marginBottom: "3%",
    },
  };

  return !loading ? (
    <div style={style.container}>
      <Confirmation />
      <div style={style.bidContainer}>
        <div style={style.label}>
          <p style={style.labelText}>Purchase Information</p>
        </div>

        <FormControl style={style.infoContainer}>
          <Input
            style={style.infoField}
            id="phone_number"
            value={`tel: ${state.phone_number}`}
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                fontWeight: "500",
              },
            }}
          />
        </FormControl>
        <FormControl style={style.infoContainer}>
          <Input
            style={style.infoField}
            id="price"
            value={`${art.title}`}
            //by ${art.artist_name}
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                fontWeight: "500",
              },
            }}
          />
        </FormControl>
        <FormControl style={style.infoContainer}>
          <Input
            style={style.infoField}
            id="price"
            value={`Price: $${art.buy_price}`}
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                fontWeight: "600",
              },
            }}
          />
        </FormControl>

        <Card method={method} />
      </div>
      {method ? (
        <SecureButton buyMode={true} onPress={handleClickOpen} />
      ) : (
        <AddCardButton user={user} />
      )}
    </div>
  ) : null;
};

export default BuyForm;
