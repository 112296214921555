import { loadStripe } from "@stripe/stripe-js";
import { PRODUCTION } from "./aws";

const appearance = {
  theme: "night",
  labels: "floating",
};

export const stripePromise = loadStripe(
  PRODUCTION
    ? "pk_live_51MDc8qHjxvqKkzAkYIjhTXU3Q0FAig1i3xK0SxdZC7qW5iYTQ2cdRod5dZK7hOa0Rb5e5bhYldzkzJ49ZrPzxteP00BubmhEi5"
    : "pk_test_51MDc8qHjxvqKkzAkhuj2kNAqWX55HY0s3LB4ybSgm7lsf1M0lfvmueMbDlMGt5wSoFF1Bk1lMXIq9nr5LF7pbd5w003nxOOG67"
);

export const stripeOptions = {
  clientSecret: "",
  appearance: appearance,
};

export const setClientSecret = (_clientSecret) => {
  stripeOptions.clientSecret = _clientSecret;
};

export const getOptions = () => {
  return stripeOptions;
};
