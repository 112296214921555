import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { View } from "../components/View";
import BidButton from "../components/BidButton";
import { addDataIntoCache, Context } from "../util/context";
import { formatPrice } from "../util/util";
import ArtCarousel from "../components/ArtCarousel";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ThemeContext } from "../util/themeContext";

export default function Display() {
  const navigate = useNavigate();
  const location = useLocation();
  const [state] = useContext(Context);

  const [img, setImg] = useState(null);

  const art = state.art_state;
  const image = state.image_state;
  const topBidder = state.bidder_state;
  const auction = state.auction_state;
  const artist = state.artist_state;

  function biddingOpen() {
    let current = new Date().getTime();
    if (auction.start_time === null) {
      return true;
    }
    let start = new Date(auction.start_time);
    let end = new Date(auction.end_time);

    return art.min_price > -1 && current >= start && current <= end;
  }

  function buyNow() {
    return art.buy_price && topBidder.price < art.buy_price && !art.sold;
  }

  function formatTime() {
    let current = new Date().getTime();
    let startTime = new Date(auction.start_time);
    let endTime = new Date(auction.end_time);

    let time = startTime.toLocaleString();
    let findex = time.lastIndexOf(",");
    if (current < endTime) {
      return (
        "Bidding will open at " +
        time.substring(findex + 2, time.length - 6) +
        " PM"
      );
    } else {
      return !art.sold && "Bidding is closed";
    }
  }

  useEffect(() => {
    addDataIntoCache("art", state);
  }, [state]);

  useEffect(() => {
    if (art.artID === "") {
      navigate("/" + location.search);
    } else {
      setImg(image.thumb);
    }
  }, [art.artID, image.thumb, location.search, navigate]);

  const { themeStyles } = useContext(ThemeContext);

  const style = {
    text: {
      color: themeStyles.color,
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "italic",
      margin: 5,
    },
    startingBidText: {
      color: themeStyles.color,
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "italic",
      marginTop: 5,
    },
    bidContainer: {
      marginTop: 30,
      marginBottom: 15,
    },
    time: {
      color: themeStyles.color,
      fontSize: "1em",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "5%",
      marginBottom: "5%",
    },
    version: {
      color: themeStyles.backgroundColor,
      position: "absolute",
      bottom: 0,
      marginBottom: 0,
      fontSize: ".5em",
      right: "3%",
    },
    contact: {
      position: "absolute",
      bottom: -17,
      marginBottom: 0,
      left: "3%",
      marginTop: 0,
      marginbottom: 0,
    },
    contactText: {
      color: "#ccc",
      fontSize: ".5em",
      lineHeight: 0.5,
    },
    titleText: {
      color: themeStyles.color,
      fontSize: 40,
      textAlign: "center",
      margin: "2%",
      width: "90%",
      wordBreak: "break-word",
      whiteSpace: "normal",
      alignSelf: "center",
    },
    artistText: {
      color: themeStyles.color,
      fontSize: 16,
      textAlign: "center",
      margin: "1%",
      marginBottom: 20,
    },
  };

  return (
    <View sx={{ paddingTop: 0, paddingBottom: "7.5%" }}>
      <Header
        eventID={art.eventID}
        onClick={() => navigate("/dashboard/" + art.eventID)}
      />
      <p style={style.titleText}>{art.title}</p>
      <p
        style={style.artistText}
        onClick={() => {
          navigate("/artist-display?" + artist.artistID);
        }}
      >
        by {artist.full_name}
      </p>

      <div style={style.carousel}>
        <ArtCarousel art={art} image={image} artist={artist} />
      </div>

      {art.min_price <= 0 ? (
        <p style={style.text}>Not For Sale</p>
      ) : topBidder.price >= art.min_price ? (
        <div style={style.bidContainer}>
          <p style={style.startingBidText}>{`Starting Bid: $${formatPrice(
            art.min_price
          )}`}</p>
          {art.sold ? (
            <p style={style.text}>
              {`Sold For $${formatPrice(topBidder.price)}`}
            </p>
          ) : (
            <p style={style.text}>
              {`Highest Bid: $${formatPrice(topBidder.price)}`}
            </p>
          )}
          {!buyNow() ? null : (
            <p style={style.text}>{`Buy Now: $${formatPrice(
              art.buy_price
            )}`}</p>
          )}
        </div>
      ) : (
        <div style={style.bidContainer}>
          <p style={style.text}>{`Starting Bid: $${formatPrice(
            art.min_price
          )}`}</p>
          {buyNow() ? (
            <p style={style.text}>{`Buy Now: $${formatPrice(
              art.buy_price
            )}`}</p>
          ) : null}
        </div>
      )}

      {art.min_price <= 0 || art.sold ? null : biddingOpen() ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <BidButton
            onPress={() => {
              navigate("/pre-bid", {
                state: { art, topBidder, img, artist, buyMode: false },
              });
            }}
            title={"Place Bid"}
          />
          {!buyNow() ? null : (
            <BidButton
              onPress={() => {
                navigate("/pre-bid", {
                  state: { art, topBidder, img, artist, buyMode: true },
                });
              }}
              title={"Buy Now"}
            />
          )}
        </div>
      ) : (
        <p style={style.time}>{formatTime()}</p>
      )}
      <Footer />
    </View>
  );
}
