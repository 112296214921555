import Dashboard from "./screens/Dashboard";

import React, { useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Display from "./screens/Display";
import ArtistDisplay from "./screens/ArtistDisplay";
import Bid from "./screens/Bid";
import Loading from "./screens/Loading";
import {
  Context,
  getSingleCacheData,
  initialState,
  reducer,
} from "./util/context";
import { ThemeContext } from "./util/themeContext";
import NewUser from "./screens/NewUser";
import PreBid from "./screens/PreBid";
import Account from "./screens/Account";
import Payment from "./screens/Payment";
import ArtistDashboard from "./screens/ArtistDashboard";
import Confirmation from "./screens/Confirmation";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [theme, _setTheme] = useState(null);

  useEffect(() => {
    let cancel = false;

    async function loadAuction() {
      const darkmode = await getSingleCacheData("darkmode");
      if (cancel) return;
      if (darkmode) {
        _setTheme("dark");
      } else {
        _setTheme("light");
      }
    }

    if (theme === null) {
      loadAuction();
    }

    return () => {
      cancel = true;
    };
  }, [theme]);

  const toggleTheme = () => {
    _setTheme(theme === "dark" ? "light" : "dark");
  };

  const setTheme = (_theme) => {
    _setTheme(_theme);
  };

  const themeStyles = {
    backgroundColor: theme === "light" ? "#fff" : "#000",
    color: theme === "light" ? "#000" : "#fff",
  };

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, themeStyles, setTheme }}
    >
      <Context.Provider value={[state, dispatch]}>
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<Loading />} />
              <Route path="/display" element={<Display />} />
              <Route path="/artist-display" element={<ArtistDisplay />} />
              <Route path="/bid" element={<Bid />} />
              <Route path="/pre-bid" element={<PreBid />} />
              <Route path="/dashboard/" element={<Loading />} />
              <Route path="/dashboard/:eventID" element={<Dashboard />} />
              <Route path="/new-user" element={<NewUser />} />
              <Route path="/account" element={<Account />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route
                path="/artist-dashboard/:eventID"
                element={<ArtistDashboard />}
              />
              <Route
                path="/artist-display/:eventID"
                element={<ArtistDisplay />}
              />
            </Routes>
          </div>
        </Router>
      </Context.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
