import React, { useContext } from "react";
import { ThemeContext } from "../util/themeContext";

const BidButton = ({ onPress, title, sx }) => {
  const { themeStyles } = useContext(ThemeContext);

  const style = {
    buttonContainer: {
      alignSelf: "center",
      borderRadius: 10,
      paddingRight: "7.5%",
      paddingLeft: "7.5%",
      backgroundColor: themeStyles.color,
    },
    text: {
      fontSize: "1.25em",
      color: themeStyles.backgroundColor,
      fontWeight: "bold",
      lineHeight: 0.5,
      textTransform: "uppercase",
    },
  };

  return (
    <button style={{ ...style.buttonContainer, ...sx }} onClick={onPress}>
      <p style={style.text}>{title}</p>
    </button>
  );
};

export default BidButton;
