import { styled } from "@mui/system";
import { Switch } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../util/themeContext";
import { addDataIntoCache } from "../util/context";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

const DarkModeSwitch = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 80,
    height: 60,
    padding: 7,
    marginTop: 30,
    marginBottom: -30,
    overflow: "visible",
    "& .MuiSwitch-switchBase": {
      margin: "0px 10px",
      padding: 0,
      transform: "translateX(-5px)",
      overflow: "visible",
      "&.Mui-checked": {
        transform: "translateX(25px)",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSwitch-thumb": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      overflow: "visible",
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#ccc",
      borderRadius: 20 / 2,
      overflow: "visible",
      height: "24px",
    },
  }));

  const _toggleTheme = () => {
    addDataIntoCache("darkmode", theme !== "dark");
    toggleTheme();
  };

  return (
    <CustomSwitch
      checked={theme === "dark"}
      onChange={_toggleTheme}
      name="checkedA"
      icon={
        <LightModeIcon
          style={{
            color: "#fff",
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        />
      }
      checkedIcon={
        <DarkModeIcon
          style={{
            color: "#000",
            backgroundColor: "#fff",
            borderRadius: "50%",
          }}
        />
      }
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: theme === "dark" ? "white" : "black",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: theme === "dark" ? "white" : "black",
        },
        "& .MuiSvgIcon-root": {
          fontSize: "2.5em", // Adjust for desired icon size
        },
      }}
    ></CustomSwitch>
  );
};

export default DarkModeSwitch;
