import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { addDataIntoCache } from "../util/context";
import { IconButton } from "@mui/material";
import { ThemeContext } from "../util/themeContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const LogoutButton = ({ sx, id }) => {
  const navigate = useNavigate();
  const _logout = () => {
    addDataIntoCache("user", null);
    addDataIntoCache("sms", null);
    addDataIntoCache("logged_in", null);
    navigate(`/dashboard/${id}`);
  };

  const { themeStyles } = useContext(ThemeContext);
  const iconColor = createTheme({
    palette: {
      primary: { main: themeStyles.backgroundColor },
      secondary: { main: themeStyles.color },
    },
  });

  const style = {
    buttonContainer: {
      alignSelf: "center",
      paddingRight: "5%",
      paddingLeft: "5%",
      position: "absolute",
      top: 8,
      right: -15,
      border: "none",
    },
    text: {
      color: "#fff",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  };

  return (
    <ThemeProvider theme={iconColor}>
      <div style={{ ...style.buttonContainer, ...sx }}>
        <IconButton onClick={_logout} color="primary">
          <LogoutIcon fontSize={"large"} />
        </IconButton>
      </div>
    </ThemeProvider>
  );
};

export default LogoutButton;
