import { FormControl, Input } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { getUserByNumber, sendSMSCode } from "../util/api";
import { addDataIntoCache, getSingleCacheData } from "../util/context";
import BidButton from "./BidButton";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../util/themeContext";
import PhoneInput from "./PhoneInput";

const formatPhone = (phone) => {
  if (phone.indexOf("+1 ") !== -1) {
    return phone.substring(3);
  }
  if (phone.indexOf("1 ") !== -1) {
    return phone.substring(2);
  }
  return phone;
};

const Login = ({ buyMode }) => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [initLoad, setInitLoad] = useState(true);
  const [verifyMode, setVerifyMode] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [userCode, setUserCode] = useState("");

  useEffect(() => {
    async function checkSMS() {
      const cached = await getSingleCacheData("sms");
      setInitLoad(false);
      if (cached && cached.code) {
        if (cancel) return;
        setVerifyMode(true);
      }
    }
    async function checkCode() {
      const cached = await getSingleCacheData("sms");
      if (cached) {
        if (cancel) return;
        setAuthCode(cached.code);
        setPhone(cached.phone);
      }
    }
    let cancel = false;

    if (initLoad && !verifyMode) {
      checkSMS();
    }
    if (verifyMode && authCode === "") {
      checkCode();
    }
    return () => {
      cancel = true;
    };
  }, [verifyMode, authCode, phone, initLoad]);

  const _setCode = () => {
    let _code = document.getElementById("code").value;
    setUserCode(_code);
  };

  async function retrieveCache(newUser) {
    const cached = await getSingleCacheData("user");
    if (cached === null || cached === false) {
      addDataIntoCache("user", newUser);
    }
  }

  async function sendNewCode() {
    const code = await sendSMSCode(phone);
    setAuthCode(code);
    setVerifyMode(true);
    addDataIntoCache("sms", { phone: formatPhone(phone), code: code });
    document.getElementById("code").value = "";
  }

  async function _onPhoneInput() {
    if (phone.length < 10) {
      setPhone("error");
      return;
    }
    sendNewCode();
  }

  async function _onSignIn() {
    if (userCode.length < 5) {
      setUserCode("error");
      return;
    }
    if (Number(userCode) !== Number(authCode)) {
      setUserCode("error");
      return;
    }
    const newUser = await getUserByNumber(formatPhone(phone));
    if (newUser === null) {
      // new user: CREATE
      navigate("/new-user", {
        state: {
          phone: formatPhone(phone),
        },
      });
    } else {
      // uncached existing user: CACHE, LOGIN
      await retrieveCache(newUser);
      addDataIntoCache("logged_in", true);
      navigate("/bid", {
        state: {
          user: newUser,
          buyMode: buyMode,
        },
      });
    }
  }

  const { themeStyles } = useContext(ThemeContext);

  const style = {
    container: {
      marginTop: "10%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    codeContainer: {
      marginTop: "10%",
      marginRight: "10%",
      marginLeft: "10%",
      paddingBottom: "10%",
      borderRadius: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    bottomContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "80%",
      paddingLeft: "10%",
      paddingRight: "10%",
    },
    inputContainer: {
      width: "70%",
      marginBottom: "5%",
    },
    text: {
      color: themeStyles.color,
      fontSize: "1em",
      marginLeft: "2.5%",
      marginBottom: "4%",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "5%",
      paddingBottom: "5%",
      fontSize: "1em",
      fontWeight: "bold",
      letterSpacing: "2px",
      textAlign: "center",
    },
    bottomText: {
      color: "#666",
      fontSize: ".5em",
      textAlign: "center",
    },
    bottomTextContainer: {
      padding: "1%",
      border: "3px solid #222",
      borderRadius: 10,
    },
  };

  return verifyMode ? (
    <div style={style.codeContainer}>
      <FormControl error={userCode === "error"} style={style.inputContainer}>
        <p style={style.text}>{`Enter code for ${phone}`}</p>
        <Input
          style={style.textField}
          id="code"
          placeholder={"One-time Code"}
          onChange={_setCode}
          type="number"
          disableUnderline={userCode !== "error"}
        />
      </FormControl>
      <div style={style.bottomContainer}>
        <BidButton
          title={"Enter"}
          onPress={_onSignIn}
          sx={{ marginTop: "1%" }}
        />
        <div style={style.bottomTextContainer}>
          <p style={style.bottomText} onClick={sendNewCode}>
            {`Didn't receive code? `}
            <br />
            {`Request new code here`}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div style={style.container}>
      <PhoneInput phone={phone} setPhone={setPhone} buyMode={buyMode} />
      <BidButton
        title={"Enter"}
        onPress={_onPhoneInput}
        sx={{ marginTop: "1%" }}
      />
    </div>
  );
};

export default Login;
