import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createBid, notifyTopBidder, notifyUser } from "../util/api";
import { ThemeContext } from "../util/themeContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { formatPrice } from "../util/util";
import { FormControl, Input } from "@mui/material";
import { getSingleCacheData } from "../util/context";
import Card from "./Card";
import SecureButton from "./SecureButton";
import { listPaymentMethods } from "../util/api";
import AddCardButton from "./AddCardButton";

const BidForm = ({ handleLoading, art, image, topBidder, bidder }) => {
  const [price, setPrice] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stripeID, setStripeID] = useState("");
  const [state, setState] = useState(null);
  const [method, setMethod] = useState(null);
  const [user, setUser] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirm) => {
    setOpen(false);
    if (!confirm) {
      handleLoading(true);
      submit();
    }
  };

  useEffect(() => {
    let cancel = false;
    async function retrieveCache() {
      const user = await getSingleCacheData("user");
      if (cancel) return;
      setState({
        full_name: user.full_name.S,
        phone_number: user.phone_number.S,
      });
      setStripeID(user.stripe_id.S);
      setUser(user);
      setLoading(false);
    }
    if (state === null) {
      retrieveCache();
    }
    return () => {
      cancel = true;
    };
  }, [state]);

  useEffect(() => {
    let cancel = false;
    async function getPaymentMethods() {
      if (stripeID === "") {
        return;
      }
      const res = await listPaymentMethods(stripeID);
      if (cancel) return;
      if (res === null || res === undefined) {
        return;
      }
      setMethod(res.data.paymentMethods.data[0]);
    }
    if (stripeID !== "") {
      getPaymentMethods();
    }
    return () => {
      cancel = true;
    };
  }, [stripeID]);

  const navigate = useNavigate();

  const Confirmation = () => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By confirming this bid, you will be charged ${formatPrice(price)}{" "}
              if your bid is the highest by the end of the event.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => handleClose(true)}>
              Disagree
            </Button>
            <Button onClick={() => handleClose(false)}>Agree</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const _setPrice = () => {
    let _price = document.getElementById("price").value;
    setPrice(_price);
  };

  const validateForm = () => {
    if (
      price.trim().length < 1 ||
      isNaN(price.trim()) ||
      parseInt(price.trim()) < art.min_price ||
      parseInt(price.trim()) - 10 < topBidder.price
    ) {
      setPrice("error");
      return false;
    }
    return price !== "error";
  };

  const _onSubmit = () => {
    if (!validateForm()) {
      return;
    }
    handleClickOpen();
  };

  const submit = () => {
    var newBid = {
      full_name: bidder.full_name,
      price: parseInt(price),
      artID: art.artID,
      userID: bidder.userID,
    };
    createBid(newBid)
      .catch((err) => {
        console.log("Error creating bid: ", err);
        return Promise.reject(err);
      })
      .then(() => {
        notifyUser(bidder, newBid);
        notifyTopBidder(topBidder, newBid);
        navigate("/confirmation", {
          state: {
            bid: newBid,
            img: image,
            topBidder,
            art,
          },
        });
      });
  };

  const { themeStyles } = useContext(ThemeContext);
  const style = {
    container: {
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    bidContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    inputContainer: {
      width: "70%",
      marginBottom: 20,
    },
    infoContainer: {
      width: "70%",
      marginBottom: "2.5%",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    infoField: {
      backgroundColor: "#CECECE",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "3%",
      paddingBottom: "3%",
    },
    textContainer: {
      color: themeStyles.color,
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      marginRight: "10%",
      marginLeft: "10%",
      fontWeight: "bold",
      fontSize: "1em",
    },
    topLabel: {
      width: "70%",
      marginTop: "-3%",
    },
    label: {
      width: "70%",
    },
    labelText: {
      color: themeStyles.color,
      marginLeft: "2%",
      marginBottom: "3%",
    },
  };

  return !loading ? (
    <div style={style.container}>
      <Confirmation />
      <div style={style.bidContainer}>
        <div style={style.label}>
          <p style={style.labelText}>Bid Amount</p>
        </div>
        <FormControl error={price === "error"} style={style.inputContainer}>
          <Input
            style={style.textField}
            id="price"
            placeholder={`Minimum bid is $${
              topBidder.price >= art.min_price
                ? formatPrice(topBidder.price + 10)
                : formatPrice(art.min_price)
            }`}
            onChange={_setPrice}
            type="number"
            disableUnderline={price !== "error"}
          />
        </FormControl>
        <div style={style.topLabel}>
          <p style={style.labelText}>Bid Information</p>
        </div>
        <FormControl style={style.infoContainer}>
          <Input
            style={style.infoField}
            id="phone_number"
            value={`tel: ${state.phone_number}`}
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                fontWeight: "500",
              },
            }}
          />
        </FormControl>
        <Card method={method} />
      </div>
      {method ? (
        <SecureButton onPress={_onSubmit} />
      ) : (
        <AddCardButton user={user} />
      )}
    </div>
  ) : null;
};

export default BidForm;
