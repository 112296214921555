import axios from "axios";
import { root } from "./aws";

const config = {
  headers: {
    Authorization: process.env.REACT_APP_API_KEY,
  },
};

export const createBid = async (newBid) => {
  var success = false;
  await axios.post(root + "/bids", newBid, config).then(async (res) => {
    if (newBid.buy_now) {
      await soldArt(newBid.artID);
    }
    await updateArt(newBid.artID, res.data.Item.bidID.S);
    success = true;
  });
  return success;
};

export const capturePayment = async (data) => {
  let success = false;
  await axios
    .post(root + "/payment", data, config)
    .then((res) => {
      console.log(res);
      success = true;
    })
    .catch((err) => {
      console.log(err);
    });
  return success;
};

export const getArt = () => {
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var id = urlParams.get("id");

  if (id === null) {
    queryString = window.location.search;
    urlParams = new URLSearchParams(queryString);
    var _id = urlParams.get("id");
    id = _id;
  }
  return axios.get(root + "/art/" + id, config);
};

export const getArtist = () => {
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var id = urlParams.get("artist");

  if (id === null) {
    queryString = window.location.search;
    urlParams = new URLSearchParams(queryString);
    var _id = urlParams.get("artist");
    id = _id;
  }
  if (id === null || id === undefined || id === "") {
    return null;
  }
  return axios.get(root + "/artist/" + id, config);
};

export const getTopBidder = async (id) => {
  if (id === undefined || id === null || id === "") {
    return {
      full_name: "",
      price: -1,
      userID: "",
    };
  }
  var topBidder = null;
  await axios
    .get(root + "/bids/" + id, config)
    .then((res) => {
      if (!res.data.Item) {
        topBidder = {
          full_name: "",
          price: -1,
          userID: "",
        };
      } else {
        topBidder = res.data.Item;
      }
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return topBidder;
};

const updateArt = async (artID, bidID) => {
  await axios
    .put(
      root + "/art/" + artID,
      {
        top_bidder: bidID,
      },
      config
    )
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log("update error", err);
    });
};

const soldArt = async (artID) => {
  await axios
    .get(root + "/art/sold/" + artID, config)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log("update error", err);
    });
};

export const getAllArt = async (eventID) => {
  return await axios.get(root + "/art/auction/" + eventID, config);
};

export const getAllArtists = async (eventID) => {
  return await axios.get(root + "/artist/event/" + eventID, config);
};

export const getAuction = async (eventID) => {
  return axios.get(root + "/auction/" + eventID, config);
};

export const getArtByArtist = async (artistID) => {
  return axios.get(root + "/art/artist/" + artistID, config);
};

export const getUserByNumber = async (phone_number) => {
  var user = null;
  await axios
    .get(root + "/user/number/" + phone_number, config)
    .then((res) => {
      user = res.data.Item;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return user;
};

export const createUser = async (user) => {
  var new_user = null;
  await axios
    .post(root + "/user", user, config)
    .then((res) => {
      new_user = res.data.Item;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return new_user;
};

export const updateUser = async (updatedUser, userID) => {
  var updated_user = null;
  await axios
    .put(root + "/user/" + userID, updatedUser, config)
    .then((res) => {
      updated_user = res.data.Item;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return updated_user;
};

export const getStripeCustomerSecret = async (id) => {
  var secret = null;
  await axios
    .get(root + "/payment/secret/" + id, config)
    .then((res) => {
      secret = res.data.client_secret;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return secret;
};

export const sendSMSCode = async (phone_number) => {
  if (phone_number === "") {
    return;
  }
  var code = null;
  await axios
    .get(root + "/sms/code/" + phone_number, config)
    .then((res) => {
      code = res.data.code;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return code;
};

export const notifyUser = async (bidder, bid) => {
  await axios.post(root + "/sms/bid/" + bidder.phone_number, bid, config);
};

export const notifyTopBidder = async (top_bidder, bid) => {
  if (top_bidder.userID === "") {
    return;
  }
  const user = await getUser(top_bidder.userID);
  bid.user = top_bidder;
  await axios.post(root + "/sms/outbid/" + user.phone_number, bid, config);
};

export const notifyUserPurchase = async (bidder, bid) => {
  await axios.post(root + "/sms/purchase/" + bidder.phone_number, bid, config);
};

export const notifyTopBidderPurchase = async (top_bidder, bid) => {
  if (top_bidder.userID === "") {
    return;
  }
  const user = await getUser(top_bidder.userID);
  bid.user = top_bidder;
  await axios.post(root + "/sms/outpurchase/" + user.phone_number, bid, config);
};

export const getUser = async (id) => {
  var user = null;
  await axios
    .get(root + "/user/" + id, config)
    .then((res) => {
      user = res.data.Item;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return user;
};

export const getArtistByID = async (id) => {
  var artist = null;
  await axios
    .get(root + "/artist/" + id, config)
    .then((res) => {
      artist = res.data.Item;
    })
    .catch((err) => {
      console.log("ERRR", err);
    });
  return artist;
};

export const listPaymentMethods = async (id) => {
  return await axios.get(root + "/payment/user/" + id, config);
};

export const deletePaymentMethod = async (id, cardID) => {
  return await axios.post(
    root + "/payment/user/" + id,
    { cardID: cardID },
    config
  );
};
