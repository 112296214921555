import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/new_logo_black.svg";
import { View } from "../components/View";
import BidButton from "../components/BidButton";
import { addDataIntoCache } from "../util/context";
import { FormControl, Input } from "@mui/material";
import { createUser } from "../util/api";
import AuctiosLogo from "../components/AuctiosLogo";
import { ThemeContext } from "../util/themeContext";

export default function NewUser() {
  const navigate = useNavigate();
  const { themeStyles, theme } = useContext(ThemeContext);
  const invert = theme === "light" ? false : true;

  const location = useLocation();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const style = {
    art: {
      width: "25%",
      alignSelf: "center",
      marginTop: "5%",
      marginBottom: "2.5%",
      filter: theme === "dark" ? "invert(1)" : "",
    },
    text: {
      color: themeStyles.color,
      fontSize: "1em",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "5%",
    },
    container: {
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    inputContainer: {
      width: "70%",
      marginBottom: "5%",
    },
    textField: {
      backgroundColor: "rgba(52, 52, 52, 0.5)",
      borderRadius: 10,
      color: themeStyles.color,
      paddingLeft: "5%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
  };

  useEffect(() => {
    if (phone === "") {
      console.log(location.state.phone);
      setPhone(location.state.phone);
      document.getElementById("phone").value = location.state.phone;
    }
  }, [location.state, location.state.phone, phone]);

  const _setName = () => {
    let _name = document.getElementById("name").value;
    setName(_name);
  };

  const _setPhone = () => {
    let _phone = document.getElementById("phone").value;
    setPhone(_phone);
  };

  const validateForm = () => {
    if (name.trim().length < 2 || name.trim().length > 26) {
      console.log("invalid name");
      setName("error");
      return false;
    } else if (phone.trim().length < 10) {
      console.log("invalid phone number");
      setPhone("error");
      return false;
    }
    return name !== "error" && phone !== "error";
  };

  const _onClick = async () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    submit();
  };

  const submit = async () => {
    var newUser = {
      full_name: name,
      phone_number: phone,
    };
    const user = await createUser(newUser);
    if (user) {
      addDataIntoCache("user", user);
      addDataIntoCache("logged_in", true);
      navigate("/payment", {
        state: {
          user: user,
          buyMode: location.state.buyMode,
        },
      });
    }
  };

  return loading ? (
    <View sx={{ backgroundColor: "black", paddingBottom: 0, paddingTop: 0 }}>
      <AuctiosLogo invert={invert} />
    </View>
  ) : (
    <View sx={{ paddingTop: 0, paddingBottom: "7.5%" }}>
      <img style={style.art} src={logo} alt="" />
      <p style={style.text}>Enter Bidding Information</p>
      <div style={style.container}>
        <FormControl error={name === "error"} style={style.inputContainer}>
          <Input
            style={style.textField}
            id="name"
            placeholder={"Full Name"}
            onChange={_setName}
            disableUnderline={name !== "error"}
          />
        </FormControl>
        <FormControl error={phone === "error"} style={style.inputContainer}>
          <Input
            style={style.textField}
            id="phone"
            placeholder={"Phone Number"}
            onChange={_setPhone}
            disabled
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: themeStyles.color,
                fontWeight: "500",
              },
            }}
          />
        </FormControl>
        <BidButton title={"Add Payment"} onPress={_onClick} />
      </div>
    </View>
  );
}
