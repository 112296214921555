import React from "react";
import black_logo from "../assets/new_logo_black.svg";

const AuctiosLogo = ({ invert }) => {
  const styling = {
    art: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "33%",
      filter: invert ? "invert(1)" : "",
    },
  };
  return <img style={styling.art} src={black_logo} alt="Auctios" />;
};

export default AuctiosLogo;
