import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addDataIntoCache, Context } from "../util/context";
import { View } from "../components/View";
import { getArtByArtist } from "../util/api";
import { getImageLink, getImageLinkHighRes } from "../util/aws";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { List, ListItem } from "@mui/material";
import { ThemeContext } from "../util/themeContext";
import Artwork from "../components/Artwork";

export default function ArtistDisplay() {
  const [DATA, setDATA] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [state] = useContext(Context);
  const [loading, handleLoading] = useState(true);

  const artist = state.artist_state;

  let social = artist.social;

  if (social) {
    if (social.substring(0, 1) === "@") {
      social = artist.social.substring(1);
    }
  }

  useEffect(() => {
    addDataIntoCache("artist", state);
  }, [state]);

  useEffect(() => {
    if (
      artist === null ||
      artist === undefined ||
      artist.artistID === "" ||
      artist.artistID === null
    ) {
      navigate("/" + location.search);
    } else {
      handleLoading(false);
    }
  }, [artist, location.search, navigate]);

  useEffect(() => {
    let cancel = false;
    async function fetch() {
      const artist_response = await getArtByArtist(artist.artistID);
      if (cancel) return;
      if (artist_response !== null) {
        const temp = [];
        artist_response.data.Items.forEach((d, i) => {
          temp.push({
            id: i,
            artID: d.artID.S,
            description: d.description.S,
            image_ref: d.image_ref.S,
            link: d.link.S,
            title: d.title.S,
            year: d.year.N,
          });
        });
        setDATA(temp);
      }
    }
    if (artist !== undefined) {
      fetch();
    }
    return () => {
      cancel = true;
    };
  }, [artist]);

  const { themeStyles } = useContext(ThemeContext);

  const style = {
    artistName: {
      color: themeStyles.color,
      fontSize: 24,
      fontStyle: "italic",
      textAlign: "center",
      margin: 2,
    },
    img: {
      width: 150,
      height: 200,
      borderRadius: 5,
      objectFit: "cover",
      float: "left",
    },
    container: {
      display: "flex",
      padding: "5%",
      alignItems: "center",
      flexDirection: "column",
    },
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
    },
    infoContainer: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column",
    },
    statement: {
      color: themeStyles.color,
      fontSize: 14,
      textAlign: "center",
    },
    divider: {
      backgroundColor: themeStyles.color,
      width: "90%",
    },
    heading: {
      color: themeStyles.color,
      fontSize: 28,
      textAlign: "center",
      fontWeight: "bold",
    },
    social: {
      color: themeStyles.color,
      textAlign: "center",
      fontSize: 14,
      marginTop: 15,
      marginBottom: 0,
    },
    artwork: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: 20,
    },
    artworkThumbnail: {
      borderRadius: 5,
      borderWidth: 5,
      borderColor: themeStyles.backgroundColor,
      // width: "85%",
      alignSelf: "center",
      objectFit: "contain",
    },
    title: {
      color: themeStyles.color,
      textDecoration: "underline",
      textAlign: "center",
      fontSize: 24,
      marginTop: 20,
      marginBottom: 10,
      wordBreak: "break-word",
      whiteSpace: "normal",
    },
    description: {
      color: themeStyles.color,
      fontSize: 16,
      textAlign: "center",
    },
  };

  return loading ? null : (
    <View>
      <Header
        eventID={artist.eventID}
        onClick={() => navigate("/artist-dashboard/" + artist.eventID)}
      />
      <div style={style.container}>
        <div style={style.imageContainer}>
          <img style={style.img} src={getImageLink(artist.image_ref)} alt="" />
          <p style={style.social}>
            <a
              href={`https://instagram.com/${social}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: themeStyles.color,
              }}
              onClick={(event) => event.stopPropagation()}
            >
              @{social}
            </a>
          </p>
        </div>
        <div style={style.infoContainer}>
          <p style={style.artistName}>{artist.full_name}</p>
          <div>
            <p style={style.statement}>{artist.bio}</p>
          </div>
        </div>
      </div>
      <hr style={style.divider} />

      <p style={style.heading}>Their Work</p>

      <List>
        {DATA.map((artwork, index) => (
          <ListItem key={index}>
            <div
              style={style.artwork}
              onClick={() => {
                navigate(`/?id=${artwork.artID}`);
              }}
            >
              <Artwork
                image={{
                  img: getImageLinkHighRes(artwork.image_ref),
                  thumb: getImageLink(artwork.image_ref),
                }}
                sx={style.artworkThumbnail}
              />
              <p style={style.title}>{artwork.title}</p>
              <p style={style.description}>{artwork.description}</p>
            </div>
          </ListItem>
        ))}
      </List>
      <Footer />
    </View>
  );
}
