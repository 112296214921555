import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../util/themeContext";
import { getSingleCacheData } from "../util/context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { getImageLink } from "../util/aws";
import { getAuction } from "../util/api";
import defaultLogo from "../assets/new_logo_black.svg";

export default function Header({ _back, onClick, eventID, _disable_profile }) {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [cache, setCache] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    let cancel = false;
    async function retrieveCache() {
      const cached = await getSingleCacheData("logged_in");
      if (cancel) return;
      setCache(cached);
      if (cached === true) setLoggedIn(true);
    }
    if (cache === null) {
      retrieveCache();
    }
    return () => {
      cancel = true;
    };
  }, [cache]);

  useEffect(() => {
    let cancel = false;
    async function retrieveCache() {
      const cached = await getSingleCacheData("art");
      if (cancel) return;
      if (
        cached === null ||
        cached.auction_state.image_ref === null ||
        cached.auction_state.image_ref === ""
      ) {
        const auction = await getAuction(eventID);
        const res = getImageLink(auction.data.Item.image_ref.S);
        if (res.endsWith("thumbnails/")) {
          setLogo(defaultLogo);
        } else {
          setLogo(res);
        }
      } else {
        const res = getImageLink(cached.auction_state.image_ref);
        setLogo(res);
      }
    }
    if (logo === null) {
      retrieveCache();
    }
    return () => {
      cancel = true;
    };
  }, [eventID, logo]);

  const { theme, themeStyles } = useContext(ThemeContext);

  const iconColor = createTheme({
    palette: {
      primary: { main: themeStyles.backgroundColor },
      secondary: { main: themeStyles.color },
    },
  });

  const logoTheme = theme === "dark" ? "" : "invert(1)";

  const style = {
    header: {
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 22,
      margin: 0,
      marginTop: 5,
      marginBottom: 0,
      color: themeStyles.backgroundColor,
    },
    headerContainer: {
      backgroundColor: themeStyles.color,
      marginBottom: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    left: {
      position: "absolute",
      left: "4%",
    },
    right: {
      position: "absolute",
      right: "14%",
      display: "flex",
    },
    rightWithoutProfile: {
      position: "absolute",
      right: "4%",
      display: "flex",
    },
    logo: {
      objectFit: "contain",
      maxHeight: 40,
      filter: logoTheme,
      minWidth: 120,
      marginBottom: "0%",
    },
    defaultLogo: {
      filter: logoTheme,
      marginBottom: "-7%",
      width: 30,
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      margin: 12,
    },
    defaultLogoContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
  };

  return (
    <div>
      <ThemeProvider theme={iconColor}>
        <div style={style.headerContainer}>
          {_back ? (
            <IconButton sx={style.left} onClick={onClick} color="primary">
              <ArrowBack fontSize={"large"} />
            </IconButton>
          ) : onClick ? (
            <IconButton sx={style.left} onClick={onClick} color="primary">
              <DashboardIcon fontSize={"large"} />
            </IconButton>
          ) : null}
          {logo === defaultLogo ? (
            <div style={style.logoContainer} onClick={onClick}>
              <div style={style.defaultLogoContainer}>
                <img src={logo} alt="" style={style.defaultLogo} />
                <h1
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Gill Sans",
                    marginLeft: -5,
                    color: theme === "light" ? "#fff" : "#000",
                  }}
                >
                  uctios
                </h1>
              </div>
              {/* <p style={style.header}>{eventID}</p> */}
            </div>
          ) : (
            <div style={style.logoContainer} onClick={onClick}>
              <img src={logo} alt="" style={style.logo} />
              {/* <p style={style.header}>{eventID}</p> */}
            </div>
          )}
          {loggedIn && !_disable_profile ? (
            <div style={style.rightWithoutProfile}>
              <IconButton
                onClick={() => navigate("/account")}
                color="primary"
                style={{ padding: 5 }}
              >
                <PersonIcon fontSize={"large"} />
              </IconButton>
            </div>
          ) : null}
        </div>
      </ThemeProvider>
    </div>
  );
}
