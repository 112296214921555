import { useWindowDimensions } from "../util/util";
import { useContext } from "react";
import { ThemeContext } from "../util/themeContext";

export const View = ({ children, sx }) => {
  const { width, height } = useWindowDimensions();
  const { themeStyles } = useContext(ThemeContext);

  const style = {
    width: "100%",
    minHeight: height,
    backgroundColor: themeStyles.backgroundColor,
    display: "flex",
    flexDirection: "column",
  };
  return <div style={{ ...style, ...sx }}>{children}</div>;
};
