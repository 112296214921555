import { createContext } from "react";
import { url } from "./aws";

const art_state = {
  artID: "",
  artist_name: "",
  dimension: "",
  eventID: "",
  image_ref: "",
  medium: "",
  min_price: 0,
  title: "",
  year: 0,
  artistID: "",
  description: "",
  buy_price: 0,
  sold: false,
};

const image_state = {
  img: "",
  thumb: "",
};

const bidder_state = {
  price: 0,
  full_name: "",
  userID: "",
};

const auction_state = {
  start_time: null,
  end_time: null,
  active: false,
  image_ref: null,
};

const artist_state = {
  artistID: null,
  bio: null,
  eventID: null,
  full_name: null,
  image_ref: null,
  medium: null,
  social: null,
};

export const initialState = {
  art_state,
  image_state,
  bidder_state,
  auction_state,
  artist_state,
};

export const reducer = (_, action) => {
  switch (action.type) {
    case "LOAD":
      return {
        art_state: {
          artID: action.payload.art.artID,
          artist_name: action.payload.art.artist_name,
          dimension: action.payload.art.dimension,
          eventID: action.payload.art.eventID,
          image_ref: action.payload.art.image_ref,
          medium: action.payload.art.medium,
          min_price: action.payload.art.min_price,
          title: action.payload.art.title,
          year: action.payload.art.year,
          artistID: action.payload.art.artistID,
          description: action.payload.art.description,
          buy_price: action.payload.art.buy_price,
          sold: action.payload.art.sold,
        },
        image_state: {
          img: action.payload.image.img,
          thumb: action.payload.image.thumb,
        },
        bidder_state: {
          price: action.payload.bidder.price,
          full_name: action.payload.bidder.full_name,
          userID: action.payload.bidder.userID,
        },
        auction_state: {
          start_time: action.payload.auction.start_time.S,
          end_time: action.payload.auction.end_time.S,
          active: action.payload.auction.active.BOOL,
          image_ref: action.payload.auction.image_ref.S,
          dark_mode: action.payload.auction.dark_mode.BOOL,
        },
        artist_state: {
          artistID: action.payload.artist.artistID,
          bio: action.payload.artist.bio,
          eventID: action.payload.artist.eventID,
          full_name: action.payload.artist.full_name,
          image_ref: action.payload.artist.image_ref,
          medium: action.payload.artist.medium,
          social: action.payload.artist.social,
        },
      };
    case "ARTIST_LOAD":
      return {
        artist_state: {
          artistID: action.payload.artist.artistID,
          bio: action.payload.artist.bio,
          eventID: action.payload.artist.eventID,
          full_name: action.payload.artist.full_name,
          image_ref: action.payload.artist.image_ref,
          medium: action.payload.artist.medium,
          social: action.payload.artist.social,
        },
      };
    default:
      break;
  }
};

export const addDataIntoCache = (path, response) => {
  // Converting our response into Actual Response form
  const data = new Response(JSON.stringify(response));

  if ("caches" in window) {
    // Opening given cache and putting our data into it
    caches.open("CACHE").then((cache) => {
      cache.put(url + "/" + path, data);
    });
  }
};
export const getSingleCacheData = async (path) => {
  if (typeof caches === "undefined") return false;

  const cacheStorage = await caches.open("CACHE");
  const cachedResponse = await cacheStorage.match(url + "/" + path);

  // If no cache exists
  if (!cachedResponse || !cachedResponse.ok) {
    return null;
  }

  return cachedResponse.json().then((item) => {
    return item;
  });
};

export const Context = createContext(initialState);
