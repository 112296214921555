import React, { useState, useContext } from "react";
import { useWindowDimensions } from "../util/util";
import { ThemeContext } from "../util/themeContext";

const Thumbnail = ({ artist, title, img }) => {
  const { width } = useWindowDimensions();
  const [loading, handleLoading] = useState(true);

  const { themeStyles } = useContext(ThemeContext);

  const style = {
    container: {
      marginRight: "5%",
      marginLeft: "5%",
      display: "flex",
      flexDirection: "row",
    },
    art: {
      borderRadius: 20,
      borderWidth: 5,
      borderColor: themeStyles.backgroundColor,
      width: "50%",
      height: width / 2,
      objectFit: "cover",
      opacity: 1,
      transition: "opacity .25s",
    },
    preload: {
      width: "50%",
      height: width / 2,
      opacity: 0.1,
      blur: 1,
      backgroundColor: "#999",
      borderRadius: 20,
    },
    doneload: {
      display: "none",
      opacity: 0,
    },
    price: {
      fontWeight: "normal",
    },
    textContainer: {
      color: themeStyles.color,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: ".75em",
      marginLeft: "5%",
    },
    text: {
      marginTop: "1%",
      marginBottom: "1%",
      wordBreak: "break-word",
      whiteSpace: "normal",
      fontSize: 18,
    },
  };

  return (
    <div style={style.container}>
      {<div style={!loading ? style.doneload : style.preload}></div>}
      <img
        src={img}
        style={loading ? { opacity: 0 } : style.art}
        alt=""
        loading={"eager"}
        onLoad={() => handleLoading(false)}
      />
      <div style={style.textContainer}>
        <p style={style.text}>@{artist}</p>
        <p style={style.text}>
          <i>{title}</i>
        </p>
      </div>
    </div>
  );
};

export default Thumbnail;
